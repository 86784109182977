<template>
  <section class="lh-Title">
    <h2
      class="lh-Title-title"
      :class="{ 'lh-Title-title--small': isSmall }"
      v-html="titleText"
    ></h2>
    <p
      v-if="underTitleText"
      class="lh-Title-underTitle"
      v-html="underTitleText"
    ></p>
  </section>
</template>

<script>
export default {
  name: "lhtitle",
  props: {
    titleText: String,
    underTitleText: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
