<template>
  <section class="lh-PreFooter-ribbon">
    <ul class="lh-PreFooter-list">
      <li class="lh-PreFooter-listItem" v-for="item in items" :key="item.svg">
        <svg class="lh-PreFooter-listItemSvg">
          <use :xlink:href="item.svg"></use>
        </svg>
        <h3 class="lh-PreFooter-listItemTitle">{{ item.title }}</h3>
        <p class="lh-PreFooter-listItemText" v-html="item.text"></p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "preFooter",
  data: () => {
    return {
      items: [
        {
          svg: "#ledNote",
          title: "Ampoule offerte",
          text: `L'ampoule est offerte. Vous pouvez la changer mais pensez à utiliser une LED pour éviter tout risque de surchauffe qui pourrait déformer la lampe.`,
        },
        {
          svg: "#paypal",
          title: "Paiement sécurisé",
          text: `Le paiement est sécurisé et s’effectue via PayPal. Pas besoin d’avoir un compte, une carte de crédit suffit ;).`,
        },
        {
          svg: "#livraison",
          title: "Livraison gratuite",
          text: `La livraison à domicile est gratuite !<br/> Cependant, l'abat jour étant fragile, la livraison ne peut s'effectuer qu'au Havre. Merci de votre compréhension.`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@/components/prefooter/_index.scss";
</style>
