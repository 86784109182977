<template>
  <div id="app">
    <LHHeader />
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </transition>
    <LHSvg />
    <PreFooter />
    <transition name="fade" mode="out-in">
      <Konami />
    </transition>
    <LHFooter />
  </div>
</template>

<script>
import Mediaqueries from "@/utlis/mediaqueries";

import LHHeader from "@/components/lhheader/LHHeader.vue";
import LHSvg from "@/components/lhsvg/LHSvg.vue";
import PreFooter from "@/components/prefooter/PreFooter.vue";
import Konami from "@/components/konami/Konami.vue";
import LHFooter from "@/components/lhfooter/LHFooter.vue";

export default {
  components: {
    LHHeader,
    LHSvg,
    PreFooter,
    Konami,
    LHFooter,
  },
  data() {
    return {
      prevHeight: 0,
    };
  },

  created() {
    if (Mediaqueries.isIos()) {
      document.body.classList.add("is-ios");
    }

    if (Mediaqueries.isWebp()) {
      document.body.classList.add("webp");
    } else {
      document.body.classList.add("no-webp");
    }

    if ("loading" in HTMLImageElement.prototype) {
      const images = document.querySelectorAll('img[loading="lazy"]');
      images.forEach((img) => {
        img.src = img.dataset.src;
      });
    } else {
      // Dynamically import the LazySizes library
      const script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js";
      document.body.appendChild(script);
    }

    setTimeout(
      console.log.bind(
        console,
        `%c Oh! Mais que viens tu faire ici petit filou ?! %c`,
        "font-family:'Gilroy-Bold';font-size: 30px;background: #000;color:#FFF;padding:10px 5px;line-height: 26px;",
        ""
      )
    );
    setTimeout(
      console.log.bind(
        console,
        `%c J'éspère juste que tu ne vas pas faire ton pirate... Si tu as vu un bug, n'hesite pas à m'envoyer un message: https://lhight.fr/contact %c`,
        "font-family:'Gilroy-Bold';font-size: 12px;padding:5px;background: #000;color:#FFF;",
        ""
      )
    );
    setTimeout(
      console.log.bind(
        console,
        `%c En attendant, il y a un konami code rien que pour toi <3!`,
        "font-family:'Gilroy-Bold';font-size: 12px;padding:5px;background: #000;color:#FFF;",
        ""
      )
    );
    // this.initSmoothScroll();
  },

  methods: {
    // initSmoothScroll() {
    //   new this.SmoothScroll(document, 120, 12);
    // },
    // SmoothScroll(target, speed, smooth) {
    //   if (target === document)
    //     target =
    //       document.scrollingElement ||
    //       document.documentElement ||
    //       document.body.parentNode ||
    //       document.body; // cross browser support for document scrolling
    //   var moving = false;
    //   var pos = target.scrollTop;
    //   var frame =
    //     target === document.body && document.documentElement
    //       ? document.documentElement
    //       : target; // safari is the new IE
    //   target.addEventListener("mousewheel", scrolled, { passive: false });
    //   target.addEventListener("DOMMouseScroll", scrolled, { passive: false });
    //   function scrolled(e) {
    //     e.preventDefault(); // disable default scrolling
    //     var delta = normalizeWheelDelta(e);
    //     pos += -delta * speed;
    //     pos = Math.max(
    //       0,
    //       Math.min(pos, target.scrollHeight - frame.clientHeight)
    //     ); // limit scrolling
    //     if (!moving) update();
    //   }
    //   function normalizeWheelDelta(e) {
    //     if (e.detail) {
    //       if (e.wheelDelta)
    //         return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1);
    //       // Opera
    //       else return -e.detail / 3; // Firefox
    //     } else return e.wheelDelta / 120; // IE,Safari,Chrome
    //   }
    //   function update() {
    //     moving = true;
    //     var delta = (pos - target.scrollTop) / smooth;
    //     target.scrollTop += delta;
    //     if (Math.abs(delta) > 0.5) requestFrame(update);
    //     else moving = false;
    //   }
    //   var requestFrame = (function () {
    //     // requestAnimationFrame cross browser
    //     return (
    //       window.requestAnimationFrame ||
    //       window.webkitRequestAnimationFrame ||
    //       window.mozRequestAnimationFrame ||
    //       window.oRequestAnimationFrame ||
    //       window.msRequestAnimationFrame ||
    //       function (func) {
    //         window.setTimeout(func, 1000 / 50);
    //       }
    //     );
    //   })();
    // },
    // beforeLeave(element) {
    //   this.prevHeight = getComputedStyle(element).height;
    // },
    // enter(element) {
    //   const { height } = getComputedStyle(element);
    //   element.style.height = this.prevHeight;
    //   setTimeout(() => {
    //     element.style.height = height;
    //   });
    // },
    // afterEnter(element) {
    //   element.style.height = "auto";
    // },
  },
};
</script>

<style lang="scss">
@import "@/scss/vendor/_normalize";
@import "@/scss/vendor/_swiper";
@import "@/scss/layout/_reset";
@import "@/scss/layout/_fonts";
@import "@/scss/layout/_wrapper";
@import "@/scss/layout/_layer";
@import "@/scss/layout/_buttons";
@import "@/scss/layout/_accordion";
@import "@/scss/layout/_form";
@import "@/scss/layout/_text";
@import "@/scss/layout/_title";
@import "@/scss/layout/_tooltip";
@import "@/scss/layout/_breadcrumb";
@import "@/scss/layout/_tarteaucitron";
</style>
