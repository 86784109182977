<template>
  <footer class="lh-Footer">
    <div class="lh-Footer-logoWrapper">
      <svg class="lh-Footer-logo" itemprop="logo">
        <use xlink:href="#logo"></use>
      </svg>
      <span class="lh-Footer-logoTitle">LHight</span>
      <p class="lh-Footer-logoText">Light up LH city !</p>
    </div>
    <ul class="lh-Footer-socialList">
      <li class="lh-Footer-socialListItem">
        <a
          class="lh-Footer-socialListItemLink"
          href="https://www.facebook.com/LHight-474534953063018"
          target="_blank"
          aria-label="Facebook"
          rel="noreferrer"
        >
          <svg class="lh-Footer-socialSVG">
            <use xlink:href="#facebook"></use>
          </svg>
        </a>
      </li>
      <li class="lh-Footer-socialListItem">
        <a
          class="lh-Footer-socialListItemLink"
          href="https://www.instagram.com/lhight76/"
          target="_blank"
          aria-label="Instagram"
          rel="noreferrer"
        >
          <svg class="lh-Footer-socialSVG">
            <use xlink:href="#instagram"></use>
          </svg>
        </a>
      </li>
      <li class="lh-Footer-socialListItem">
        <a
          class="lh-Footer-socialListItemLink"
          href="mailto:hello@lhight.fr?subject=Hello&nbsp;LHight&amp;body=Hello&nbsp;Stan,"
          aria-label="Email"
        >
          <svg class="lh-Footer-socialSVG">
            <use xlink:href="#email"></use>
          </svg>
        </a>
      </li>
    </ul>
    <p class="lh-Footer-credits">
      Designed & Developed with ♡ by Stanislas Kerné in Le Havre - 2022
    </p>
  </footer>
</template>

<script>
export default {
  name: "lhfooter",

  data: () => {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/components/lhfooter/_index.scss";
</style>
