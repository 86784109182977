<template>
  <div>
    <Intro />
    <div id="lh-endIntroTitle">
      <LHTitle :titleText="titleColors" :underTitleText="underTitleColors" />
    </div>
    <Colors />
    <LHTitle :titleText="titleVersions" :underTitleText="underTitleVersions" />
    <VersionsIntro />
    <LHTitle :titleText="titleCrossSell" />
    <CrossSell />
    <!-- <LHTime /> -->
    <FAQContact />
  </div>
</template>

<script>
import Intro from "@/components/intro/Intro.vue";
import LHTitle from "@/components/lhtitle/LHTitle.vue";
import Colors from "@/components/colors/Colors.vue";
import VersionsIntro from "@/components/versionsIntro/VersionsIntro.vue";
// import LHTime from "@/components/lhtime/LHTime.vue";
import CrossSell from "@/components/crosssell/CrossSell.vue";
import FAQContact from "@/components/faqcontact/FAQContact.vue";

export default {
  name: "home",
  data: () => {
    return {
      titleColors: "LHight existe en plusieurs couleurs",
      underTitleColors:
        "Selon les modèles, LHight est unie, de deux, ou trois couleurs.<br>Certaines lampes peuvent avoir un socle et/ou un abat jour de couleur différente.",
      titleVersions: "Deux versions",
      underTitleVersions:
        "LHight existe en deux versions dans lesquelles la disposition de la main gauche (formant le « H ») diffère.<br>Dans les deux versions l’abat-jour peut tourner sur lui-même.",
      titleCrossSell:
        "Les LHight<span class='lh-Title-title--italic'>(s)</span>",
    };
  },
  components: {
    Intro,
    LHTitle,
    Colors,
    VersionsIntro,
    // LHTime,
    CrossSell,
    FAQContact,
  },
};
</script>
