<template>
  <section class="lh-Intro1">
    <button
      @click="scrollToId('#lh-ThreeIntro-TextContainer--intro')"
      class="lh-ThreeIntro-scrollText js-lh-ThreeIntro-onWindowScroll"
    >
      <span>Faites défiler ou cliquer pour allumer LH</span>
      <div class="lh-ThreeIntro-scrollbutton">
        <span class="mouse"></span>
      </div>
    </button>
    <div id="lh-Intro" class="lh-Intro">
      <div id="statCenter"></div>
      <div
        v-if="isLoading"
        class="lh-ThreeIntro-loader js-lh-ThreeIntro-loader"
      >
        <svg class="lh-ThreeIntro-loaderLogo">
          <use xlink:href="#logo"></use>
        </svg>
        <span class="lh-ThreeIntro-loaderText">Chargement...</span>
        <span class="lh-ThreeIntro-loaderText js-lh-loaderBarProgress"></span>
      </div>
      <div id="pinThreeIntro">
        <div class="js-lh-ThreeIntro lh-ThreeIntro">
          <canvas
            id="js-lh-ThreeIntro-viewport"
            class="lh-ThreeIntro-viewport"
          ></canvas>
        </div>
      </div>
    </div>
    <div
      id="lh-ThreeIntro-TextContainer--fake"
      class="lh-ThreeIntro-TextContainer js-lh-ThreeIntro-TextContainer"
      data-name="fake"
    ></div>
    <div
      id="lh-ThreeIntro-TextContainer--intro"
      class="lh-ThreeIntro-TextContainer js-lh-ThreeIntro-TextContainer"
    >
      <h1 class="lh-ThreeIntro-Title">LHight est une lampe imprimée.</h1>
      <p class="lh-ThreeIntro-Text">
        Découvrez la première lampe imaginée, modélisée et imprimée en 3D au
        Havre, au style de LH.<br /><br />"La Californie à 2h de Paris"
        <a
          href="https://www.youtube.com/channel/UChY1SnCfEJX62Rjx2FrA2Uw"
          target="_blank"
          rel="noreferrer"
          ><i>Francis</i></a
        >.
      </p>
      <button
        class="lh-ThreeIntro-NextButton"
        @click="scrollToId('#lh-ThreeIntro-TextContainer--unique')"
      >
        <span class="lh-ThreeIntro-NextButtonArrow"></span>
      </button>
    </div>
    <div
      id="lh-ThreeIntro-TextContainer--unique"
      class="lh-ThreeIntro-TextContainer js-lh-ThreeIntro-TextContainer"
    >
      <h2 class="lh-ThreeIntro-Title">Elle est unique.</h2>
      <p class="lh-ThreeIntro-Text">
        Les LHight<i>(s)</i> possèdent toutes de petites particularités qui
        donnent à chacune des lampes un caractère unique.<br />Chaque lampe a
        son propre numéro imprimé en 3D sur son socle.
      </p>
      <button
        class="lh-ThreeIntro-NextButton"
        @click="scrollToId('#lh-ThreeIntro-TextContainer--sign')"
      >
        <span class="lh-ThreeIntro-NextButtonArrow"></span>
      </button>
    </div>
    <div
      id="lh-ThreeIntro-TextContainer--sign"
      class="lh-ThreeIntro-TextContainer js-lh-ThreeIntro-TextContainer"
    >
      <h2 class="lh-ThreeIntro-Title">Signée en 3D.</h2>
      <p class="lh-ThreeIntro-Text">
        Une signature en lithophanie apparaît sur l'abat jour. Une petite phrase
        se rélève lorsque LHight est allumée.
      </p>
      <button
        class="lh-ThreeIntro-NextButton"
        @click="scrollToId('#lh-ThreeIntro-TextContainer--5')"
      >
        <span class="lh-ThreeIntro-NextButtonArrow"></span>
      </button>
    </div>
    <div
      id="lh-ThreeIntro-TextContainer--5"
      class="lh-ThreeIntro-TextContainer js-lh-ThreeIntro-TextContainer"
    >
      <h2 class="lh-ThreeIntro-Title">Fabriquée avec amour.</h2>
      <p class="lh-ThreeIntro-Text">
        Chaque lampe LHight est personnalisée, imprimée sous un oeil attentif
        puis assemblée à la main avec ♡.
      </p>
      <button
        class="lh-ThreeIntro-NextButton"
        @click="scrollToId('#lh-endIntroTitle')"
      >
        <span class="lh-ThreeIntro-NextButtonArrow"></span>
      </button>
    </div>
    <ul class="lh-ThreeIntro-quickList js-lh-ThreeIntro-quickList">
      <li class="lh-ThreeIntro-quickListItem">
        <router-link class="lh-ThreeIntro-quickListLink" to="/boutique"
          >Aller à la boutique</router-link
        >
      </li>

      <li class="lh-ThreeIntro-quickListItem">
        <router-link class="lh-ThreeIntro-quickListLink" to="/apropos"
          >En savoir plus</router-link
        >
      </li>
    </ul>
  </section>
</template>

<script>
import Mediaqueries from "@/utlis/mediaqueries";
import * as Three from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ limitCallbacks: true });

export default {
  name: "intro",
  data() {
    return {
      isLoading: true,
      scene: null,
      realCamera: null,
      floor: null,
      lhight: null,
      lamp: null,
      fullLampe: null,
      globalLight: null,
      spotLight: null,
      spotLightLHight: null,
      angle: 0,
      mouse: null,
      mouseX: null,
      mouseY: null,
      lampez: null,
      camera: null,
      renderer: null,
      mesh: null,
    };
  },

  methods: {
    init() {
      let container = document.getElementById("js-lh-ThreeIntro-viewport");
      this.scene = new Three.Scene();
      this.lamp = new Three.Group();
      this.mouse = new Three.Vector2();
      this.clock = new Three.Clock();

      this.initRealCamera();
      this.initFloor();
      this.initText();
      this.initSTL();
      this.initLights();

      this.renderer = new Three.WebGLRenderer({
        alpha: true,
        antialias: true,
        powerPreference: "high-performance",
        canvas: container,
      });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = Three.PCFSoftShadowMap;
      this.scene.background = new Three.Color(0x000000);
    },

    scrollToId(id) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
      });
    },

    initRealCamera() {
      this.realCamera = new Three.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.01,
        30
      );
      this.realCamera.position.x = 12;
      this.realCamera.position.y = 6;
      this.realCamera.rotation.y = Math.PI / 2;
    },

    initFloor() {
      const floorMaterial = new Three.MeshLambertMaterial({
        color: 0xffffff,
      });
      floorMaterial.color.convertSRGBToLinear();
      const floorGeometry = new Three.PlaneBufferGeometry(75, 50, 100, 100);

      this.floor = new Three.Mesh(floorGeometry, floorMaterial);
      this.floor.position.x = -6;
      this.floor.rotation.y = Math.PI / 2;
      this.floor.receiveShadow = true;

      this.scene.add(this.floor);
    },

    initText() {
      const material = new Three.MeshLambertMaterial({
        color: 0xffffff,
      });
      material.color.convertSRGBToLinear();
      const fontJSON = {
        glyphs: {
          H: {
            ha: 924,
            x_min: 90,
            x_max: 833,
            o: "m 643 972 l 833 972 l 833 0 l 643 0 l 643 403 l 282 403 l 282 0 l 90 0 l 90 972 l 282 972 l 282 586 l 643 586 l 643 972 z ",
          },
          L: {
            ha: 671,
            x_min: 90,
            x_max: 653,
            o: "m 282 183 l 653 183 l 653 0 l 90 0 l 90 972 l 282 972 l 282 183 z ",
          },
          g: {
            ha: 869,
            x_min: 46,
            x_max: 789,
            o: "m 614 694 l 789 694 l 789 33 q 683 -212 789 -128 q 426 -297 576 -297 q 228 -254 314 -297 q 97 -128 143 -211 l 250 -40 q 432 -137 303 -137 q 565 -93 517 -137 q 614 33 614 -49 l 614 108 q 392 3 535 3 q 145 106 244 3 q 46 358 46 210 q 146 610 46 506 q 392 714 246 714 q 614 608 535 714 l 614 694 m 280 222 q 307 195 280 222 q 419 168 335 168 q 559 222 504 168 q 614 358 614 275 q 559 494 614 440 q 419 547 504 547 q 280 494 335 547 q 225 358 225 440 q 280 222 225 275 z ",
          },
          h: {
            ha: 801,
            x_min: 82,
            x_max: 731,
            o: "m 468 714 q 656 638 582 714 q 731 426 731 561 l 731 0 l 551 0 l 551 404 q 514 510 551 474 q 414 547 476 547 q 303 504 344 547 q 261 375 261 461 l 261 0 l 82 0 l 82 972 l 261 972 l 261 617 q 468 714 326 714 z ",
          },
          i: {
            ha: 343,
            x_min: 61,
            x_max: 282,
            o: "m 171 778 q 94 810 126 778 q 61 888 61 843 q 94 965 61 932 q 171 999 126 999 q 249 965 217 999 q 282 888 282 932 q 249 810 282 843 q 171 778 217 778 m 82 0 l 82 694 l 261 694 l 261 0 l 82 0 z ",
          },
          t: {
            ha: 535,
            x_min: 22,
            x_max: 479,
            o: "m 479 522 l 322 522 l 322 233 q 340 181 322 197 q 393 162 358 164 q 479 163 428 160 l 479 0 q 219 35 294 -21 q 143 233 143 90 l 143 522 l 22 522 l 22 694 l 143 694 l 143 835 l 322 889 l 322 694 l 479 694 l 479 522 z ",
          },
        },
        familyName: "Gilroy-Bold",
        ascender: 1074,
        descender: -315,
        underlinePosition: -243,
        underlineThickness: 69,
        boundingBox: { yMin: -365, xMin: -576, yMax: 1354, xMax: 1519 },
        resolution: 1000,
        original_font_information: {
          format: 0,
          copyright: "Copyright © 2016 by Radomir Tinkov. All rights reserved.",
          fontFamily: "Gilroy-Bold",
          fontSubfamily: "☞",
          uniqueID:
            "com.myfonts.easy.radomir-tinkov.gilroy.bold.wfkit2.version.4BUZ",
          fullName: "☞Gilroy-Bold",
          version:
            "Version 1.000;PS 001.000;hotconv 1.0.88;makeotf.lib2.5.64775;com.myfonts.easy.radomir-tinkov.gilroy.bold.wfkit2.version.4BUZ",
          postScriptName: "Gilroy-Bold",
          manufacturer: "Radomir Tinkov",
          designer: "Radomir Tinkov",
          manufacturerURL: "www.tinkov.info",
          designerURL: "www.tinkov.info",
        },
        cssFontWeight: "normal",
        cssFontStyle: "normal",
      };

      const lhightGeometry = new Three.TextBufferGeometry("LHight", {
        font: new Three.FontLoader().parse(fontJSON),
        size: 1.5,
        height: 0.2,
        material: 0,
        bevelThickness: 1,
        extrudeMaterial: 1,
      });

      this.lhight = new Three.Mesh(lhightGeometry, material);
      this.lhight.castShadow = true;
      this.lhight.rotation.y = Math.PI / 2;
      this.lhight.position.z = 3.1;
      this.lhight.position.y = 6;
      this.lhight.position.x = 4;

      this.scene.add(this.lhight);
    },

    initSTL() {
      const that = this;
      var manager = new Three.LoadingManager();
      manager.onStart = function () {
        // console.log("Started loading file");
      };

      manager.onLoad = function () {
        // console.log("Loading complete!");
        that.isLoading = false;
        that.readyToAnim();
        that.animate();
      };

      manager.onProgress = function (url, itemsLoaded, itemsTotal) {
        // console.log(
        //   "Loading file: " +
        //     url +
        //     ".\nLoaded " +
        //     itemsLoaded +
        //     " of " +
        //     itemsTotal +
        //     " files."
        // );
        document.querySelector(".js-lh-loaderBarProgress").innerText =
          (itemsLoaded / itemsTotal) * 100 + "%";
      };

      manager.onError = function (url) {
        console.log("There was an error loading " + url);
      };

      var loader = new STLLoader(manager);
      var materialHands = new Three.MeshPhongMaterial({
        color: 0xeeeeee,
        specular: 0x111111,
        shininess: 100,
        wireframe: false,
      });
      materialHands.color.convertSRGBToLinear();
      loader.load(`${process.env.BASE_URL}fullLampeA.stl`, function (geometry) {
        that.fullLampe = new Three.Mesh(geometry, materialHands);
        that.fullLampe.position.y = 0.5;
        that.fullLampe.rotation.set(-Math.PI / 2, 0, Math.PI / 2);
        that.fullLampe.scale.set(0.03, 0.03, 0.03);
        that.fullLampe.castShadow = true;
        that.fullLampe.receiveShadow = true;
        that.lamp.add(that.fullLampe);
      });
    },

    initLights() {
      this.globalLight = new Three.HemisphereLight(
        "hsl(0,0%,87%);",
        0xffffff,
        0 //A REMMETRE A 0 POUR LANIM
      );

      this.spotLight = new Three.SpotLight(0xffffe0, 0.8, 13, Math.PI / 7, 1);
      this.spotLight.position.set(15, 10, 0);
      this.spotLight.target.position.set(0, 7, 0);
      this.spotLight.castShadow = true;
      this.spotLight.target.updateMatrixWorld();

      this.spotLightLHight = new Three.SpotLight(0xffffff, 2, 60, 0.8, 0);
      this.spotLightLHight.position.set(2, 6.5, 0);
      this.spotLightLHight.target.position.set(20, 6.5, 0);
      this.spotLightLHight.target.updateMatrixWorld();

      this.lampLight = new Three.PointLight(0xdddddd, 0, 11.7, 2);
      this.lampLight.position.x = 0;
      this.lampLight.position.y = 11.7;
      this.lampLight.castShadow = true;
      this.lamp.add(this.lampLight);

      this.scene.add(this.globalLight);
      this.scene.add(this.spotLight.target);
      this.scene.add(this.spotLight);
      this.scene.add(this.spotLightLHight);
      this.scene.add(this.spotLightLHight.target);
    },

    readyToAnim() {
      this.scene.add(this.lamp);

      if (!Mediaqueries.isTouchDevice()) {
        document.addEventListener("mousemove", this.onMouseMove, {
          passive: true,
        });
      }

      window.addEventListener("resize", this.onWindowResize, { passive: true });

      this.onWindowScroll();

      this.lampez = this.fullLampe.rotation.z;
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.angle -= this.clock.getDelta();
      this.spotLightLHight.position.z = -2.5 * Math.sin(this.angle);

      this.renderer.render(this.scene, this.realCamera);
    },

    onMouseMove(event) {
      this.mouseX = event.clientX - window.innerWidth / 2;
      this.mouseY = event.clientY - window.innerHeight / 2;

      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      this.fullLampe.rotation.z =
        (this.lampez + this.mouseX) / 300 + this.lampez;
    },

    onWindowResize() {
      this.realCamera.aspect = window.innerWidth / window.innerHeight;
      this.realCamera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    onWindowScroll() {
      const timelinefake = new gsap.timeline();

      timelinefake
        .to(
          ".js-lh-ThreeIntro-onWindowScroll",
          {
            duration: 0.5,
            autoAlpha: 0,
          },
          0
        )
        .to(this.spotLightLHight, { duration: 0, intensity: 0 })
        .to(this.lhight.position, { duration: 4, x: 17, y: 5.3, z: 2.8 }, 0)
        .to(this.spotLight, { duration: 1, distance: 25 }, 0)
        .to(this.globalLight, { duration: 1, intensity: 0.8 }, 0.7)
        .addLabel("moveRotateLamp", "-=2.5")
        .to(this.lampLight, { duration: 4, intensity: 1 }, "moveRotateLamp")
        .to(
          this.lamp.rotation,
          { duration: 4, y: -(Math.PI * 2 - Math.PI / 6) },
          "moveRotateLamp"
        );

      if (Mediaqueries.isMinMedium()) {
        timelinefake
          .to(this.lamp.position, { duration: 4, z: 6 }, "moveRotateLamp")
          .to(
            this.spotLight.target.position,
            { duration: 4, z: 5 },
            "moveRotateLamp"
          );
      } else {
        timelinefake
          .to(this.lamp.position, { duration: 3, y: 4 }, "moveRotateLamp")
          .to(
            this.spotLight.target.position,
            { duration: 3, y: 9 },
            "moveRotateLamp"
          )
          .to(
            this.realCamera.position,
            { duration: 3, x: 17 },
            "moveRotateLamp"
          );
      }
      timelinefake
        .to(
          this.globalLight.color,
          { duration: 1, r: 0.67, g: 0.84, b: 0.9 },
          "50%"
        )
        .to(".js-lh-ThreeIntro-quickList", { duration: 1, autoAlpha: 1 });

      // unique
      const timelineUnique = new gsap.timeline();
      if (Mediaqueries.isMinMedium()) {
        timelineUnique
          .to(this.realCamera.position, { duration: 1, x: 3, y: 2, z: 4 })
          .to(
            this.lamp.rotation,
            { duration: 1, x: -Math.PI / 6, z: -Math.PI / 2 },
            0
          );
      } else {
        timelineUnique
          .to(this.realCamera.position, { duration: 1, x: 3, y: 5.5, z: 0.25 })
          .to(this.lamp.rotation, { duration: 2, z: -Math.PI / 2 }, "-=2")
          .to(this.lamp.rotation, { duration: 2, y: -Math.PI * 2 }, "-=0.5")
          .to(
            this.spotLight.position,
            { duration: 2, x: 2.5, y: 8, z: 1 },
            "-=2"
          )
          .to(this.spotLight, { duration: 2, angle: 0.5 }, "-=2")
          .to(
            this.spotLight.target.position,
            2,
            { x: 0.5, y: 5.8, z: 0.3 },
            "-=2"
          )
          .to(
            this.spotLight,
            { duration: 2, intensity: 0.8, distance: 7 },
            "-=2"
          );
      }
      timelineUnique.to(
        this.globalLight.color,
        { duration: 0.5, r: 0.97, g: 0.84, b: 0.3 },
        0.3
      );
      const timelineSign = new gsap.timeline();
      timelineSign
        .to(
          this.realCamera.position,
          { duration: 1, x: 12, y: 6, z: 0 },
          "rotateBack"
        )
        .to(this.lamp.rotation, { duration: 1, x: 0, z: 0 }, "rotateBack")
        .to(
          this.lamp.rotation,
          { duration: 1, y: -Math.PI },
          "rotateBack+=0.7"
        );

      if (Mediaqueries.isMinMedium()) {
        timelineSign.to(
          this.realCamera.position,
          { duration: 1, x: 6, y: 10, z: 4 },
          "-=0.5"
        );
      } else {
        timelineSign.to(
          this.realCamera.position,
          { duration: 1, x: 6, y: 13, z: 0 },
          "-=0.5"
        );
      }
      timelineSign.to(
        this.globalLight.color,
        { duration: 0.5, r: 0.13, g: 0.69, b: 0.72 },
        0.3
      );

      // love
      const timelineLove = new gsap.timeline();
      timelineLove
        .to(
          this.realCamera.position,
          { duration: 1, x: 12, y: 6, z: 0 },
          "love"
        )
        .to(this.lamp.rotation, { duration: 1, x: 0, y: 0.5, z: 0 }, "love")
        .to(
          this.globalLight.color,
          { duration: 0.5, r: 0.98, g: 0.21, b: 0.21 },
          0.3
        );

      const sections = gsap.utils.toArray(".js-lh-ThreeIntro-TextContainer");
      sections.forEach((eachPanel) => {
        gsap.to(eachPanel, {
          scrollTrigger: {
            trigger: eachPanel,
            start: "top top",
            scrub: 1,
            immediateRender: false,
            snap: {
              snapTo: 1,
              duration: { min: 0.2, max: 0.6 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              delay: 0.1, // wait 0.2 seconds from the last scroll event before doing the snapping
              ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
            },
          },
        });
        gsap.to(eachPanel, {
          autoAlpha: 1,
          scrollTrigger: {
            trigger: eachPanel,
            start: "top 20%",
            end: "50% 50%",
            scrub: 0,
            immediateRender: false,
          },
        });
        gsap.to(eachPanel, {
          autoAlpha: 0,
          scrollTrigger: {
            trigger: eachPanel,
            start: "bottom 80%",
            end: "bottom 60%",
            scrub: 0,
            immediateRender: false,
          },
        });
        // if (eachPanel.getAttribute("data-name")) {
        //   console.log(eachPanel.getAttribute("data-name"));
        //   const name = eachPanel.getAttribute("data-name");
        //   const animationName = `timeline${name}`;
        //   ScrollTrigger.create({
        //     trigger: `#lh-ThreeIntro-TextContainer--${name}`,
        //     animation: timelinefake,
        //     scrub: 0,
        //     start: "bottom bottom",
        //     toggleActions: "restart pause reverse pause",
        //     id: animationName,
        //     end: "bottom top",
        //     immediateRender: false,
        //   });
        // }
      });

      ScrollTrigger.create({
        trigger: "#lh-Intro",
        scrub: 0,
        start: "top top",
        toggleActions: "restart pause reverse pause",
        id: "lh-Intro",
        pin: true,
        pinSpacing: false,
        end:
          document.querySelectorAll(".lh-ThreeIntro-TextContainer").length *
          window.innerHeight,
        immediateRender: false,
        // onToggle: (self) => console.log("toggled, isActive:", self.isActive),
        // onUpdate: (self) => {
        //   console.log(
        //     "progress:",
        //     self.progress.toFixed(3),
        //     "direction:",
        //     self.direction,
        //     "velocity",
        //     self.getVelocity()
        //   );
        // },
      });

      ScrollTrigger.create({
        trigger: "#lh-ThreeIntro-TextContainer--fake",
        animation: timelinefake,
        scrub: 0,
        start: "bottom bottom",
        toggleActions: "restart pause reverse pause",
        id: "lh-1",
        end: "bottom top",
        immediateRender: false,
      });

      ScrollTrigger.create({
        trigger: "#lh-ThreeIntro-TextContainer--intro",
        animation: timelineUnique,
        scrub: 0,
        start: "bottom bottom",
        toggleActions: "restart pause reverse pause",
        id: "lh-2",
        end: "bottom top",
        immediateRender: false,
      });

      ScrollTrigger.create({
        trigger: "#lh-ThreeIntro-TextContainer--unique",
        animation: timelineSign,
        scrub: 0,
        start: "bottom bottom",
        toggleActions: "restart pause reverse pause",
        id: "lh-3",
        end: "bottom top",
        immediateRender: false,
      });

      ScrollTrigger.create({
        trigger: "#lh-ThreeIntro-TextContainer--sign",
        animation: timelineLove,
        scrub: 0,
        start: "bottom bottom",
        toggleActions: "restart pause reverse pause",
        id: "lh-4",
        end: "bottom top",
        immediateRender: false,
      });

      const timelineEnd = new gsap.timeline();
      timelineEnd
        .to(".js-lh-ThreeIntro-quickList", { duration: 1, autoAlpha: 0 }, 0.1)
        .to(this.globalLight, { duration: 1, intensity: 0 });

      ScrollTrigger.create({
        trigger: "#lh-endIntroTitle",
        animation: timelineEnd,
        id: "lh-EndIntro",
        scrub: 0,
        start: "25% bottom",
        toggleActions: "restart pause reverse pause",
        end: "bottom bottom",
        immediateRender: false,
      });
    },
  },

  mounted() {
    this.init();
  },

  destroyed() {
    ScrollTrigger.getAll().forEach((t) => t.kill());
    this.renderer.dispose();
    this.renderer.forceContextLoss();
    this.scene.remove(this.mesh);
  },
};
</script>

<style lang="scss">
@import "@/components/intro/_index.scss";
</style>
