import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "LHight - Accueil",
      metaTags: [
        {
          name: "description",
          content:
            "Les lampes 3D LHight représentent le signe LH formé par deux mains, un clin d'oeil à la ville du Havre. Elles sont toutes uniques, imaginées, modélisées, imprimées, numérotées et signées en 3D au Havre. Plusieurs variations de couleurs et de positions disponibles. Livraison gratuite!",
        },
        {
          property: "og:description",
          content:
            "Les lampes 3D LHight représentent le signe LH formé par deux mains, un clin d'oeil à la ville du Havre. Elles sont toutes uniques, imaginées, modélisées, imprimées, numérotées et signées en 3D au Havre. Plusieurs variations de couleurs et de positions disponibles. Livraison gratuite!",
        },
      ],
    },
  },
  {
    path: "/boutique",
    name: "shop",
    meta: {
      title: "LHight - Boutique",
      metaTags: [
        {
          name: "description",
          content:
            "La boutique des lampes 3D LHight qui représentent le signe LH formées par deux mains, un clin d'oeil à la ville du Havre. Elles sont toutes uniques, imaginées, modélisées, imprimées, numérotées et signées en 3D au Havre. Plusieurs variations de couleurs et de positions disponibles. Livraison gratuite!",
        },
        {
          property: "og:description",
          content:
            "La boutique des lampes 3D LHight qui représentent le signe LH formées par deux mains, un clin d'oeil à la ville du Havre. Elles sont toutes uniques, imaginées, modélisées, imprimées, numérotées et signées en 3D au Havre. Plusieurs variations de couleurs et de positions disponibles. Livraison gratuite!",
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "boutique" */ "../views/Shop.vue"),
  },
  {
    path: "/boutique/lhight:id",
    name: "product",
    meta: {
      title: `LHight`,
      metaTags: [
        {
          name: "description",
          content:
            "La boutique des lampes 3D LHight qui représentent le signe LH formées par deux mains, un clin d'oeil à la ville du Havre. Elles sont toutes uniques, imaginées, modélisées, imprimées, numérotées et signées en 3D au Havre. Plusieurs variations de couleurs et de positions disponibles. Livraison gratuite!",
        },
        {
          property: "og:description",
          content:
            "La boutique des lampes 3D LHight qui représentent le signe LH formées par deux mains, un clin d'oeil à la ville du Havre. Elles sont toutes uniques, imaginées, modélisées, imprimées, numérotées et signées en 3D au Havre. Plusieurs variations de couleurs et de positions disponibles. Livraison gratuite!",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Product.vue"),
  },
  {
    path: "/apropos",
    name: "About",
    meta: {
      title: "LHight - À propos",
      metaTags: [
        {
          name: "description",
          content:
            "D’où vient cette lumière ? Pour en savoir plus sur les lampes LHight",
        },
        {
          property: "og:description",
          content:
            "D’où vient cette lumière ? Pour en savoir plus sur les lampes LHight",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/versions",
    name: "versions",
    meta: {
      title: "LHight - Versions",
      metaTags: [
        {
          name: "versions",
          content: "Deux versions de la lampe LHight existent.",
        },
        {
          property: "og:description",
          content: "Deux versions de la lampe LHight existent.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "versions" */ "../views/Versions.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "LHight - Contact",
      metaTags: [
        {
          name: "description",
          content:
            "Si vous avez des questions ou des suggestions sur les lampes LHight, n'hesitez pas à m'envoyer un petit message ;)",
        },
        {
          property: "og:description",
          content:
            "Si vous avez des questions ou des suggestions sur les lampes LHight, n'hesitez pas à m'envoyer un petit message ;)",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/merci",
    name: "Confirmer",
    meta: {
      title: "LHight - Merci",
      metaTags: [
        {
          name: "description",
          content:
            "Merci beaucoup pour votre achat! Je vous tiendrai informer de la date de livraison de votre lampe ;)",
        },
        {
          property: "og:description",
          content:
            "Merci beaucoup pour votre achat! Je vous tiendrai informer de la date de livraison de votre lampe ;)",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "success" */ "../views/Success.vue"),
  },
  {
    path: "/soldout",
    name: "Soldout",
    meta: {
      title: "LHight - Désolé",
      metaTags: [
        {
          name: "description",
          content:
            "Ah non! Désolé, la lampe que vous voulez acheter est déjà vendue... Peut-etre qu'un autre modèle vous interesse ?",
        },
        {
          property: "og:description",
          content:
            "Ah non! Désolé, la lampe que vous voulez acheter est déjà vendue... Peut-etre qu'un autre modèle vous interesse ?",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "soldout" */ "../views/Soldout.vue"),
  },
  {
    path: "/annuler",
    name: "Annuler",
    meta: {
      title: "LHight - Commande annulée",
      metaTags: [
        {
          name: "description",
          content:
            "Dommage, il y a eu un problème et votre commande a été annulée... Veuillez réessayer plus tard.",
        },
        {
          property: "og:description",
          content:
            "Dommage, il y a eu un problème et votre commande a été annulée... Veuillez réessayer plus tard.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "annuler" */ "../views/Cancelled.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    meta: {
      title: "LHight - CGV",
      metaTags: [
        {
          name: "description",
          content: "Conditions Générales d'Utilisation",
        },
        {
          property: "og:description",
          content: "Conditions Générales d'Utilisation",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
  },
  {
    path: "/confidentialite",
    name: "Confidentiality",
    meta: {
      title: "LHight - Confidentialité",
      metaTags: [
        {
          name: "description",
          content: "Charte de respect de la vie privée.",
        },
        {
          property: "og:description",
          content: "Charte de respect de la vie privée.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/Confidentiality.vue"),
  },
  {
    path: "*",
    name: "Error404",
    meta: {
      title: "LHight - 404",
      metaTags: [
        {
          name: "description",
          content:
            "Oups, c'etait la guerre ici et la page que vous voulez consulter est partie...",
        },
        {
          property: "og:description",
          content:
            "Oups, c'etait la guerre ici et la page que vous voulez consulter est partie...",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "error404" */ "../views/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

router.afterEach((to) => {
  // window.scrollTo({ top: 0 });
  window.ga("set", "page", to.path); // eslint-disable-line  no-undef
  window.ga("send", "pageview"); // eslint-disable-line  no-undef
});

export default router;
