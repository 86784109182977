<template>
  <section class="lh-FaqContact">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box" v-if="isAbout">
        <div class="lh-Wrapper-boxContent" name="about">
          <router-link :to="{ name: 'About' }">À propos d'LHight</router-link>
        </div>
      </div>
      <div class="lh-Wrapper-box" v-if="isBoutique">
        <div class="lh-Wrapper-boxContent" name="boutique">
          <router-link :to="{ name: 'shop' }">Voir la boutique</router-link>
        </div>
      </div>
      <div class="lh-Wrapper-box" v-if="isContact">
        <div class="lh-Wrapper-boxContent" name="contact">
          <router-link :to="{ name: 'Contact' }">Contactez-moi</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { imagePath } from "@/mixins/imagePath.js";

export default {
  name: "faqcontact",
  props: {
    isBoutique: {
      type: Boolean,
      default: false,
    },
    isAbout: {
      type: Boolean,
      default: true,
    },
    isContact: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data: () => {
    return {};
  },
  mixins: [imagePath],

  mounted() {},

  created() {},

  destroyed() {},

  methods: {
    getJpgImagePath(product, index) {
      let extension = "jpg";
      if (document.body.classList.contains("webp")) {
        extension = "webp";
      }
      const img = require(`@/assets/images/colors/${product.colors[index]}.${extension}`);
      return `background-image: url(${img})`;
    },
  },
};
</script>

<style lang="scss">
@import "@/components/faqcontact/_index.scss";
</style>
