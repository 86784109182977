<template>
  <section class="lh-Colors">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--small lh-Wrapper-box--dark">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Colors-listName">
            <li
              v-for="(color, index) in colors"
              :key="color.index"
              class="lh-Colors-listNameItem"
              :class="color.isActive ? 'lh-Colors-listNameItem--active' : ''"
            >
              <p class="lh-Colors-texture">
                {{ colors[index].texture }}
              </p>
              <p
                class="lh-Colors-colorName js-lh-Colors-colorName"
                :data-color="colors[index].name"
              >
                {{ colors[index].color }}
              </p>
            </li>
          </ul>
          <router-link class="lh-LinkTo lh-Colors-link" to="/boutique"
            >Voir les lampes</router-link
          >
        </div>
      </div>
      <div
        class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--transparent"
      >
        <ul class="lh-Colors-list">
          <li
            class="lh-Colors-listItem"
            :class="color.isActive ? 'lh-Colors-listItem--active' : ''"
            :data-color="color.name"
            v-for="(color, index) in colors"
            :key="color.index"
            @mouseenter="openColors($event, index)"
          >
            <div class="lh-Wrapper-boxContent">
              <p class="lh-Colors-colorName js-lh-Colors-colorName">
                {{ colors[index].label }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap, Power3 } from "gsap";

export default {
  name: "colors",
  data() {
    return {
      activeColorIndex: 0,
      colors: [
        {
          name: "ivory",
          texture: "Glossy",
          color: "Blanc",
          label: "Ivoire",
          isActive: true,
        },
        {
          name: "silk",
          texture: "Glossy",
          color: "Blanc",
          label: "Soie",
          isActive: false,
        },
        {
          name: "granite",
          texture: "Mate",
          color: "Gris",
          label: "Granite",
          isActive: false,
        },
        {
          name: "white",
          texture: "Mate",
          color: "Blanc",
          label: "Neige",
          isActive: false,
        },
        {
          name: "transparent",
          texture: "Brille dans la nuit",
          color: "Blanc",
          label: "Translucide",
          isActive: false,
        },
      ],
    };
  },
  methods: {
    openColors(target, index) {
      const colorTexture = ".lh-Wrapper-box--small .lh-Colors-texture";
      const colorName = ".lh-Wrapper-box--small .js-lh-Colors-colorName";
      const colorBigTexture = target.currentTarget.querySelector(
        ".lh-Wrapper-box--big .lh-Colors-colorName"
      );
      let direction = "+";

      if (!this.colors[index].isActive) {
        this.colors.map((item) => (item.isActive = false));
        this.colors[index].isActive = true;

        const tl = new gsap.timeline();

        if (this.activeColorIndex > index) {
          direction = "-";
        }

        this.activeColorIndex = index;

        tl.fromTo(
          [colorTexture, colorName, colorBigTexture],
          {
            duration: 1,
            autoAlpha: 0,
            ease: Power3.easeNone,
            // x: `${direction}60`,
          },
          {
            autoAlpha: 1,
            duration: 1,
            ease: Power3.easeNone,
            // x: "0",
            // stagger: 0.1,
          }
        );
        tl.fromTo(
          colorBigTexture,
          { duration: 1, backgroundPosition: `${direction}60% 50%` },
          { duration: 1, backgroundPosition: "-0% 50%" },
          0
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/components/colors/_index.scss";
</style>
