import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [
      {
        id: 0,
        name: "LHight #0",
        description: `
          Première lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
          <br>Elle a été imaginée, modélisée, imprimée, numérotée et signée en 3D.
          <br>La robe de LHight #0 est unie : blanc ivoire.
          <br>Le blanc ivoire reflète la lumiére, la rendant brillante une fois allumée.`,
        paypalId: "R8HHGDDQJ59WJ",
        isSold: false,
        colors: ["ivory"],
        colorName: "Blanc ivoire glossy",
        wireColor: "Blanc",
        pictures: 6,
        version: 0,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5"],
      },
      {
        id: 1,
        name: "LHight #1",
        description: `
        Deuxième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #1 est unie, du socle à l'abat-jour en blanc soie.
        <br>Sa couleur soie brillante, légèrement bleutée, reflètera les lumières et couleurs de votre intérieur.`,
        paypalId: "BHR46NPX47USL",
        isSold: true,
        colors: ["silk"],
        colorName: "Blanc soie glossy",
        wireColor: "Translucide",
        pictures: 6,
        version: 1,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5", "6"],
      },
      {
        id: 2,
        name: "LHight #2",
        description: `
          Troisième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
          <br>LHight #2 est imprimée en gris granite.
          <br>Son gris est un clin d'oeil au béton qui fait le charme de notre ville.`,
        paypalId: "CGZDSUARYSLL2",
        isSold: false,
        colors: ["granite"],
        colorName: "Gris granite mate",
        wireColor: "Translucide",
        pictures: 6,
        version: 0,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5"],
      },
      {
        id: 3,
        name: "LHight #3",
        description: `
          Quatrième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>

          <br>LHight #3 est, tout comme sa soeur jumelle, la LHight #2, imprimée en gris granite.
          <br>Seule la position des mains change, le H apparaît paume visible.`,
        paypalId: "ZU8GT83JMWYYN",
        isSold: false,
        colors: ["granite"],
        colorName: "Gris granite mate",
        wireColor: "Translucide",
        pictures: 6,
        version: 1,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4"],
      },
      {
        id: 4,
        name: "LHight #4",
        description: `
        Cinquième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #4 est la première lampe de la collection imprimée en deux couleurs.
        <br>Le socle et les mains sont imprimés en gris granite.
        <br>L'abat-jour quant à lui, est imprimé en blanc ivoire ce qui lui ajoute une touche de brillance.`,
        paypalId: "QJPF2LS9UHJGC",
        isSold: false,
        colors: ["granite", "silk"],
        colorName: "Blanc ivoire glossy & Gris granite mate",
        wireColor: "Blanc",
        pictures: 6,
        version: 0,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5", "6"],
      },
      {
        id: 5,
        name: "LHight #5",
        description: `
        Sixième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #5 ressemble à sa soeur jumelle, la LHight #4, seule la position des mains change.
        <br>Le socle et les mains sont imprimés en gris granite.
        <br>L'abat-jour quant à lui, est imprimé en blanc ivoire ce qui lui ajoute une touche de brillance.`,
        paypalId: "27WF4PVELCHQC",
        isSold: false,
        colors: ["granite", "silk"],
        colorName: "Blanc ivoire glossy & Gris granite mate",
        wireColor: "Blanc",
        pictures: 6,
        version: 1,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "5", "6"],
      },
      {
        id: 6,
        name: "LHight #6",
        description: `
        Septième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #6 est la première lampe de la collection dont le socle est assorti à l'abat-jour.
        <br>Le socle, l'abat-jour sont imprimés en blanc soie et les mains sont imprimées en gris granite.
        <br>Son gris est un clin d'oeil au béton qui fait le charme de notre ville.`,
        paypalId: "WYFVEYEFJ8VM6",
        isSold: false,
        colors: ["silk", "granite", "silk"],
        colorName: "Blanc ivoire glossy & Gris granite mate",
        wireColor: "Blanc",
        pictures: 6,
        version: 0,
        price: "300",
        isGlowNight: false,
        images: ["1", "4", "5"],
      },
      {
        id: 7,
        name: "LHight #7",
        description: `
        Huitième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #7 est la deuxième lampe de la collection dont le socle est assorti à l'abat-jour.
        <br>Le socle, l'abat-jour sont imprimés en blanc neige et les mains sont imprimées en blanc ivoire.`,
        paypalId: "L8ZCJWJBQVSBY",
        isSold: false,
        colors: ["ivory", "blanc"],
        colorName: "Blanc ivoire & blanc neige",
        wireColor: "Blanc",
        pictures: 6,
        version: 1,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5", "6"],
      },
      {
        id: 8,
        name: "LHight #8",
        description: `
        Neuvième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #8 est unie, du socle à l'abat-jour en blanc neige.
        <br>Ce blanc neige est un clin d'oeil au pot de yahourt qui fait le charme de notre ville.`,
        paypalId: "F4B7266E9NKVS",
        isSold: false,
        colors: ["blanc"],
        colorName: "Blanc neige",
        wireColor: "Blanc",
        pictures: 6,
        version: 0,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5", "6"],
      },
      {
        id: 9,
        name: "LHight #9",
        description: `
        Dixième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #9 est, tout comme sa soeur jumelle, la LHight #8, imprimée en blanc neige.
        <br>Seule la position des mains change, le H apparaît paume visible.`,
        paypalId: "73MEM3F7F57L8",
        isSold: false,
        colors: ["blanc"],
        colorName: "Blanc neige",
        wireColor: "Blanc",
        pictures: 6,
        version: 1,
        price: "300",
        isGlowNight: false,
        images: ["1", "2", "3", "4", "5", "6"],
      },
      {
        id: 10,
        name: "LHight #10",
        description: `
        Onzième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #10 est la première lampe de la collection imprimée en blanc phosphorescent.
        <br>De jour, LHight #10 a une couleur blanche translucide.
        <br>De nuit, elle s'illumine en bleu grace à la lumière accumulée pendant la journée.`,
        paypalId: "97MZQVWZG8T8Y",
        isSold: false,
        colors: ["transparent"],
        colorName: "Translucide phosphorescent",
        wireColor: "Blanc",
        pictures: 6,
        version: 1,
        price: "300",
        isGlowNight: true,
        isNightOn: false,
        images: ["1", "2", "3", "4", "5", "6", "7", "8"],
      },
      {
        id: 11,
        name: "LHight #11",
        description: `
        Douzième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>LHight #11 est imprimée en deux couleurs.
        <br>Le socle et les mains sont imprimés en gris granite tandis que l'abat-jour est imprimé en blanc translucide.
        <br>L'abat-jour d'LHight #11 est blanc translucide dans le jour et s'illumine en bleu lorque la nuit tombe.`,
        paypalId: "P5LDXE7YKQL7E",
        isSold: false,
        colors: ["granite", "transparent"],
        colorName: "Gris Granite & Translucide phosphorescent",
        wireColor: "Translucide",
        pictures: 8,
        version: 0,
        price: "300",
        isGlowNight: true,
        isNightOn: false,
        images: ["1", "2", "3", "4", "5", "6", "7", "8"],
      },
      {
        id: 12,
        name: "LHight #12",
        description: `
        Treizième lampe de la collection.<span style="color: white; font-size: 10px">(Pssst! Et ouais, on commence à 0 chez les geeks...)</span>
        <br>Tout comme sa soeur jumelle la LHight #11, LHight #12 est imprimée en gris granite.
        <br> Son abat-jour, imprimé en blanc phosphorescent refléte une lueur bleutée dans la nuit.
        <br>Seule la position des mains change, le H apparaît paume visible.`,
        paypalId: "DSSTL39SWKUVG",
        isSold: false,
        colors: ["granite", "transparent"],
        colorName: "Gris Granite & Translucide phosphorescent",
        wireColor: "Translucide",
        pictures: 8,
        version: 1,
        price: "300",
        isGlowNight: true,
        isNightOn: false,
        images: ["1", "2", "3", "4", "5", "6", "7", "8"],
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    product: (state) => (id) => {
      return state.products.filter((p) => p.id === Number(id))[0];
    },
  },
});
