export const imagePath = {
  methods: {
    makeWebpImagePath(product, isGlow) {
      if (isGlow) {
        return require(`../assets/images/products/lhight${product.id}/${product.images[6]}.webp`);
      }
      return require(`../assets/images/products/lhight${product.id}/${product.images[0]}.webp`);
    },

    makeWebpImagesPath(product, productImg) {
      return require(`../assets/images/products/lhight${product.id}/${productImg}.webp`);
    },

    makePngImagePath(product, isGlow) {
      if (isGlow) {
        return require(`../assets/images/products/lhight${product.id}/${product.images[6]}.png`);
      }
      return require(`../assets/images/products/lhight${product.id}/${product.images[0]}.png`);
    },

    makePngImagesPath(product, productImg) {
      return require(`../assets/images/products/lhight${product.id}/${productImg}.png`);
    },

    makeJpgImagePath(product, index) {
      return require(`../assets/images/colors/${product.colors[index]}.jpg`);
    },
  },
};
