<template>
  <section class="lh-VersionsIntro">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--medium">
        <div class="lh-VersionsIntro-Container" data-version="0">
          <p class="lh-VersionsIntro-Number">0</p>
          <p class="lh-VersionsIntro-Desc">Dos de la main</p>
          <picture>
            <source
              srcset="~@/assets/images/versions/version0.webp"
              type="image/webp"
            />
            <source
              srcset="~@/assets/images/versions/version0.jpg"
              type="image/jpeg"
            />
            <img
              class="lh-VersionsIntro-Img"
              alt="Version 0"
              srcset="~@/assets/images/versions/version0.png"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
      <p class="lh-VersionsIntro-Separator">&amp;</p>
      <div class="lh-Wrapper-box lh-Wrapper-box--medium lh-Wrapper-box--gray">
        <div class="lh-VersionsIntro-Container" data-version="1">
          <p class="lh-VersionsIntro-Number">1</p>
          <p class="lh-VersionsIntro-Desc">Paume de face</p>
          <picture>
            <source
              srcset="~@/assets/images/versions/version1.webp"
              type="image/webp"
            />
            <source
              srcset="~@/assets/images/versions/version1.jpg"
              type="image/jpeg"
            />
            <img
              class="lh-VersionsIntro-Img"
              alt="Version 1"
              srcset="~@/assets/images/versions/version1.png"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'versions' }"
      aria-label="interagir avec les deux versions d'LHight"
      class="lh-VersionsIntro-Play"
    >
      <span class="lh-CrossSell-link">Voir les versions en 3D</span>
      <svg class="lh-VersionsIntro-PlayIcon">
        <use xlink:href="#rotate"></use>
      </svg>
    </router-link>
  </section>
</template>

<script>
import { imagePath } from "@/mixins/imagePath.js";

export default {
  name: "versionsIntro",
  data: () => {
    return {};
  },
  methods: {},
  mixins: [imagePath],
  mounted() {},
};
</script>

<style lang="scss">
@import "@/components/versionsIntro/_index.scss";
</style>
