import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.ga("set", "page", router.currentRoute.path); // eslint-disable-line  no-undef
window.ga("send", "pageview"); // eslint-disable-line  no-undef
