<template>
  <div>
    <header
      class="lh-Header"
      :class="[
        isMounted ? 'lh-Header--loaded' : '',
        isHeaderVisible ? '' : 'lh-Header--up',
      ]"
    >
      <button
        class="lh-Link lh-Link--button lh-Link--dark lh-Header-link lh-MenuBurger"
        @click="toggleMenu"
        aria-label="ouvrir le menu"
      >
        <span class="lh-MenuBurger-line lh-MenuBurger--1"></span>
        <span class="lh-MenuBurger-line lh-MenuBurger--2"></span>
        <span class="lh-MenuBurger-line lh-MenuBurger--3"></span>
        <span class="lh-MenuBurger-label">Menu</span>
      </button>
      <router-link class="lh-Header-logo" to="/" aria-label="Fermer Menu">
        <svg class="icon logo"><use xlink:href="#logo"></use></svg>
      </router-link>
    </header>
    <div class="lh-Layer js-lh-Layer">
      <button
        class="lh-Layer-CloseButton"
        @click="toggleMenu"
        aria-label="Fermer Menu"
      ></button>
      <nav class="lh-Nav">
        <div class="lh-Menu">
          <ul class="lh-Menu-list">
            <li
              class="lh-Menu-item"
              v-for="item in primaryItems"
              :key="item.name"
            >
              <router-link
                class="lh-Link lh-Menu-link js-lh-MenuLink"
                :to="item.link"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="lh-MenuSocial">
          <ul class="lh-MenuSocial-list">
            <li
              class="lh-MenuSocial-item"
              v-for="item in secondaryItems"
              :key="item.name"
            >
              <a
                class="lh-Link lh-MenuSocial-link js-lh-MenuLink"
                rel="noreferrer"
                :href="item.link"
                target="_blank"
                >{{ item.name }}</a
              >
            </li>
          </ul>
          <ul class="lh-MenuLegal-list">
            <li
              class="lh-MenuLegal-listItem"
              v-for="item in ternaryItems"
              :key="item.name"
            >
              <router-link
                class="lh-MenuLegal-listLink js-lh-LegalLink"
                :to="item.link"
                >{{ item.name }}</router-link
              >
            </li>
            <li class="lh-MenuLegal-listItem">
              <a
                href="#tarteaucitron"
                class="lh-MenuLegal-listLink js-lh-LegalLink"
                >Cookies</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="lh-LayerClose js-lh-LayerClose" @click="toggleMenu"></div>
  </div>
</template>

<script>
import { gsap, Power3, Power2 } from "gsap";

export default {
  name: "lhheader",

  data: () => {
    return {
      menu: {},
      isMenuOpen: false,
      isMounted: false,
      lastScrollTop: 0,
      isHeaderVisible: true,
      primaryItems: [
        {
          name: "Accueil",
          link: "/",
        },
        {
          name: "Boutique",
          link: "/boutique/",
        },
        {
          name: "Versions",
          link: "/versions/",
        },
        {
          name: "À propos",
          link: "/apropos/",
        },
        {
          name: "Contact",
          link: "/contact/",
        },
      ],
      secondaryItems: [
        {
          name: "Facebook",
          link: "https://www.facebook.com/LHight-474534953063018",
        },
        {
          name: "Instagram",
          link: "https://www.instagram.com/lhight76/",
        },
        {
          name: "Email",
          link: "mailto:hello@lhight.fr?subject=Hello&nbsp;LHight&amp;body=Hello&nbsp;Stan,",
        },
      ],
      ternaryItems: [
        {
          name: "Conditions générales",
          link: "/terms/",
        },
        {
          name: "Confidentialité",
          link: "/confidentialite/",
        },
      ],
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  mounted() {
    this.isMounted = true;

    this.menu = new gsap.timeline({
      paused: true,
      reversed: true,
    });

    this.menu
      .fromTo(
        ".js-lh-LayerClose",
        0.2,
        {
          autoAlpha: 0,
          ease: Power3.easeIn,
        },
        {
          autoAlpha: 1,
          ease: Power3.easeNone,
        }
      )
      .to(
        ".lh-MenuBurger--1",
        0.5,
        {
          rotation: 45,
          transformOrigin: "left 50%",
          ease: Power2.easeInOut,
        },
        0
      )
      .to(
        ".lh-MenuBurger--2",
        0.5,
        {
          autoAlpha: 0,
        },
        0
      )
      .to(
        ".lh-MenuBurger--3",
        0.5,
        {
          rotation: -45,
          transformOrigin: "left 50%",
          ease: Power2.easeInOut,
        },
        0
      )
      .fromTo(
        ".js-lh-Layer",
        0.6,
        {
          ease: Power3.easeIn,
          x: "0",
        },
        {
          ease: Power3.easeNone,
          x: "100%",
        },
        0
      )
      .staggerFromTo(
        ".js-lh-MenuLink",
        0.6,
        {
          autoAlpha: 0,
          ease: Power3.easeIn,
          x: "-60",
        },
        {
          autoAlpha: 1,
          ease: Power3.easeNone,
          x: "0",
        },
        0.2
      )
      .fromTo(
        ".js-lh-LegalLink",
        2,
        {
          autoAlpha: 0,
          ease: Power3.easeIn,
        },
        {
          autoAlpha: 1,
          ease: Power3.easeNone,
        },
        "-=1"
      );
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  watch: {
    $route() {
      if (this.isMenuOpen) {
        this.toggleMenu();
      }
    },
  },

  methods: {
    handleScroll() {
      const delta = 5;
      const header = document.querySelector(".lh-Header");
      const navbarHeight = header.offsetHeight;
      const st = window.scrollY;

      if (Math.abs(this.lastScrollTop - st) <= delta) {
        return;
      }
      if (st > this.lastScrollTop && st > navbarHeight) {
        this.isHeaderVisible = false;
      } else if (
        st + window.innerHeight <
        document.documentElement.scrollHeight
      ) {
        this.isHeaderVisible = true;
      }

      this.lastScrollTop = st;
    },

    toggleMenu() {
      if (this.menu.reversed()) {
        this.isMenuOpen = true;
        this.menu.timeScale(1).play();
      } else {
        this.isMenuOpen = false;
        this.menu.timeScale(3).reverse();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/components/lhheader/_index.scss";
@import "@/components/lhheader/_menu.scss";
</style>
