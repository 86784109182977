const $breakpoints = {
  xsmall: 640,
  small: 768,
  medium: 1024,
  large: 1280,
  xlarge: 1850,
};

export const isXsmall = () => {
  return window.matchMedia(`(max-width: ${$breakpoints.small - 1}px)`).matches;
};

export const isSmall = () => {
  return window.matchMedia(
    `(min-width: ${$breakpoints.small}px) and (max-width: ${
      $breakpoints.medium - 1
    }px)`
  ).matches;
};

export const isMedium = () => {
  return window.matchMedia(
    `(min-width: ${$breakpoints.medium}px) and (max-width: ${
      $breakpoints.large - 1
    }px)`
  ).matches;
};

export const isLarge = () => {
  return window.matchMedia(
    `(min-width: ${$breakpoints.large}px) and (max-width: ${
      $breakpoints.xlarge - 1
    }px)`
  ).matches;
};

export const isMinXsmall = () => {
  return window.matchMedia(`(min-width: ${$breakpoints.xsmall}px)`).matches;
};

export const isMinSmall = () => {
  return window.matchMedia(`(min-width: ${$breakpoints.small}px)`).matches;
};

export const isMinMedium = () => {
  return window.matchMedia(`(min-width: ${$breakpoints.medium}px)`).matches;
};

export const isMinLarge = () => {
  return window.matchMedia(`(min-width: ${$breakpoints.large}px)`).matches;
};

export const isMinXlarge = () => {
  return window.matchMedia(`(min-width: ${$breakpoints.xlarge}px)`).matches;
};

export const isIos = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const isWebp = () => {
  const elem = document.createElement("canvas");

  if (elem.getContext && elem.getContext("2d")) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
};

export const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return true;
  }
  return false;
};

export const isTouchDevice = () => {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    "ontouchstart" in window ||
    // eslint-disable-next-line no-undef
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return mq(query);
};

export default {
  isXsmall,
  isSmall,
  isMedium,
  isLarge,
  isMinXsmall,
  isMinSmall,
  isMinMedium,
  isMinLarge,
  isMinXlarge,
  isIos,
  isAndroid,
  isTouchDevice,
  isWebp,
};
