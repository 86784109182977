<template>
  <section v-if="displayKonami" class="lh-Konami" @click="hide()">
    <div class="lh-Konami-content">
      <p class="lh-Konami-contentText">"Elle est où la poulette ?"</p>
      <svg class="lh-Konami-contentSvg">
        <use xlink:href="#logo"></use>
      </svg>
      <p class="lh-Konami-contentText">"Elle est bien cachée ?"</p>
    </div>
    <span
      class="lh-Konami-confetti"
      v-for="index in 100"
      :key="index"
      :data-index="index"
    ></span>
  </section>
</template>

<script>
export default {
  name: "konami",

  data: () => {
    return {
      keys: [],
      code: "38,38,40,40,37,39,37,39,66,65",
      displayKonami: false,
      confetti: 129,
    };
  },

  created() {
    window.addEventListener("keydown", (e) => {
      this.keys.push(e.keyCode);
      if (this.keys.toString().indexOf(this.code) >= 0) {
        this.show();
      }
    });
  },

  methods: {
    show() {
      this.keys = [];
      this.displayKonami = true;
    },

    hide() {
      this.keys = [];
      this.displayKonami = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/components/konami/_index.scss";
</style>
